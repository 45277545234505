import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { InView } from 'react-intersection-observer';
import {
    FiChevronLeft,
    FiChevronRight,
    FiPaperclip,
    FiUserPlus,
    FiSend,
    FiInfo,
} from 'react-icons/fi';
import { Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { useAppStateSelector } from '../../../store/utilities/useAppStateSelector';
import styles from './organizationalInbox.module.scss';
import commonStyles from './../inbox.module.scss';
import { formatMessageSentAt, getNamesCharacters } from '../../../utils/helpers';
import {
    getCurrentMessages,
    loadMoreMessages,
    sendContent,
    getNewMessages,
    removeAConversation,
    getInboxData,
    getVideoList,
} from '../inboxActionsCreator';
import { useDispatch } from 'react-redux';
import { inboxActions, useInboxSelector } from '../inboxSlice';
import { routeUrls } from '../../../utils/constants';
import { useAuthSelector } from '../../auth/authSlice';
import { ConversationDetail } from '../ConversationDetail';
import { Message } from '../../../components/message/Message';
import { InboxTabContainer } from '../../../components/inboxTabContainer/InboxTabContainer';
import { GreyText } from '../../../components/greyText/GreyText';
import { FilePicker } from '../../../components/filePicker/FilePicker';
import { axios } from '../../../services/axiosService';
import { MessageType } from '../../../types/Conversation';
import { Spinner } from '../../../components/spinner/Spinner';
import MultipartUploader from '../../../utils/multipartUploader';

const Organization = (props: any) => {
    return (
        <div
            className={commonStyles.userTabContainerUn}
            onClick={() => props.updateGroups(props.index)}
        >
            <div className={styles.organizationTab}>
                <div className={commonStyles.roundName}>{getNamesCharacters(props.item.name)}</div>
                <div className={styles.organizationName}>{props.item.name}</div>
            </div>
            <FiChevronRight size={18} color="grey" />
        </div>
    );
};

const SubGroup = (props: any) => {
    return (
        <div className={commonStyles.userTabContainerUn} onClick={props.onGroupSelect}>
            <div className={styles.organizationTab}>
                <div className={commonStyles.roundName}>{getNamesCharacters(props.item.name)}</div>
                <div className={styles.organizationName}>{props.item.name}</div>
            </div>
            <FiChevronRight size={18} color="grey" />
        </div>
    );
};
const Individual = (props: any) => {
    const dispatch = useDispatch();

    return (
        <div
            key={props.index}
            className={
                props.item.id === props.userId
                    ? commonStyles.userTabContainer
                    : commonStyles.userTabContainerUn
            }
            onClick={async () => {
                await props.getMessages(props.item.id);
                props.setSelectedConversation(props.item);
                dispatch(getVideoList(props.item.id));
            }}
        >
            <div className={styles.organizationTabForIndivdual}>
                <div className={commonStyles.containerPadding}>
                    <div
                        className={
                            props.item.unread > 0
                                ? props.item.id === props.userId
                                    ? commonStyles.coloringTabSelect
                                    : commonStyles.coloringTabSelect
                                : props.item.id === props.userId
                                ? commonStyles.coloringTabSelect
                                : commonStyles.coloringTabUnSelect
                        }
                    ></div>
                </div>
                {props.item ? (
                    props.item.profileImage ? (
                        <img
                            className={styles.roundImageList}
                            src={props.item.profileImage}
                            alt="Profile"
                        />
                    ) : (
                        <div className={commonStyles.roundName}>
                            {getNamesCharacters(`${props.item.firstName} ${props.item.lastName}`)}
                        </div>
                    )
                ) : (
                    ''
                )}
                <div className={commonStyles.requestMiddleContainer}>
                    <label
                        className={
                            props.item.id === props.userId ? styles.boldSelected : commonStyles.bold
                        }
                    >
                        {props.item.firstName} {props.item.lastName}
                    </label>
                    <div className={props.item.lastMessage?.videoId ? commonStyles.italic : ''}>
                        <label
                            className={
                                props.item.id === props.userId ? styles.selected : commonStyles.name
                            }
                        >
                            {props.item.lastMessage?.contents
                                ? props.item.lastMessage.contents.slice(0, 20) +
                                  (props.item.lastMessage.contents.length > 20 ? '...' : '')
                                : ''}{' '}
                        </label>
                    </div>
                </div>
            </div>
            <div className={commonStyles.rightSideInboxUser}>
                <label className={props.item.id === props.userId ? styles.fillWhite : ''}>
                    {props.item.lastMessage
                        ? formatMessageSentAt(props.item.lastMessage.sentAt)
                        : ''}
                </label>
                {props.item.lastMessage?.videoId ? (
                    <div>
                        <FiPaperclip color="grey" size={16} />
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
};

export const OrganizationInbox = () => {
    const [type, setType] = useState('organizations');
    const [groups, setGroups] = useState([] as any);
    const [orgIndex, setOrgIndex] = useState(-1);
    const [groupIndex, setGroupIndex] = useState(-1);
    const [userSelected, setUserSelected] = useState('');
    const [myInterval, setMyInterval] = useState<any>();
    const [inputMessage, setInputMessage] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [onload, setOnLoad] = useState(true);
    const [imageUploading, setImageUploading] = useState(false);
    const [setInfo, setInfoSection] = useState(false);
    const [selectedConversation, setSelectedConversation] = useState<any>();

    const inboxData = useAppStateSelector((state) => state.inbox);
    const currentInbox = useAppStateSelector((state) => state.inbox.currentMessagesOrg);
    const userId = useAppStateSelector((state) => state.auth.databaseUser?.id);
    const currentGroups = useInboxSelector((state) => state.groupsConversations);
    const organizations = useInboxSelector((state) => state.organizations);
    const user = useAuthSelector((state) => state.databaseUser);

    const history = useHistory();
    const dispatcher = useDispatch();

    let click: any = null;

    const emptyInbox = !inboxData.organizations.length;

    const removeConversation = (id: any) => {
        dispatcher(removeAConversation(id));
        clearInterval(myInterval);
        goBack();
        setInfoSection(false);
        dispatcher(getInboxData());
    };

    const sendMessage = () => {
        if (!inputMessage) return;
        dispatcher(
            sendContent(
                currentInbox.conversation.id,
                inputMessage,
                userId,
                true,
                getNamesCharacters(user?.firstName + ' ' + user?.lastName),
                user?.profileImage
            )
        );
        setInputMessage('');
    };

    const loadData = async () => {
        if (!currentInbox.loadCompleted) {
            dispatcher(loadMoreMessages(currentInbox.conversation.id, pageNumber + 1, true));
            setPageNumber(pageNumber + 1);
        }
    };

    const toggleInfoSection = () => {
        setInfoSection(!setInfo);
    };

    const handleMouseDown = (id: String, isOther: Boolean) => {
        click = setTimeout(() => {
            dispatcher(
                inboxActions.setMessageModal({
                    value: true,
                    messageId: id,
                    conversationId: userSelected,
                    otherUserMessage: isOther,
                    type: true,
                })
            );
        }, 500);
    };

    const handleMouseUp = () => {
        clearTimeout(click);
    };

    const getMessages = async (id: string) => {
        clearInterval(myInterval);
        setUserSelected(id);
        dispatcher(getCurrentMessages(id, true));
        setOnLoad(false);
        loadMessagesOnInterval(id);
    };

    const loadMessagesOnInterval = async (id: String) => {
        const interval = setInterval(() => {
            let time = new Date(Date.now() - 3000).toISOString();
            dispatcher(getNewMessages(id, time, userId, true));
        }, 10000);
        setMyInterval(interval);
    };

    useEffect(() => {
        if (orgIndex !== -1 && groupIndex !== -1) {
            dispatcher(
                inboxActions.setConversationOfGroup(organizations[orgIndex].groups[groupIndex])
            );
        }
    }, [organizations, dispatcher, orgIndex, groupIndex]);

    useEffect(() => {
        return () => {
            clearInterval(myInterval);
        };
    }, [myInterval]);

    const updateGroups = (index: number) => {
        setOrgIndex(index);
        setGroups(inboxData.organizations[index].groups);
        setType('groups');
    };

    const onGroupSelect = async (group: any, index: number) => {
        if (group.role === 'Student') {
            await getMessages(group.conversationId);
            setSelectedConversation(group);
            dispatcher(getVideoList(group.conversationId));
        } else {
            updateIndividualChats(index);
        }
    };

    const goBack = () => {
        clearInterval(myInterval);
        if (type === 'groups') {
            setType('organizations');
            setOrgIndex(-1);
        } else if (type === 'individuals') {
            dispatcher(inboxActions.setConversationOfGroup([]));
            setType('groups');
        }
        setUserSelected('');
    };

    const updateIndividualChats = (index: number) => {
        setGroupIndex(index);
        dispatcher(
            inboxActions.setConversationOfGroup(inboxData.organizations[orgIndex].groups[index])
        );
        setType('individuals');
    };

    const afterFileChange = async (file: File, thumbnail: string) => {
        setImageUploading(true);
        const isVideo = file.type.indexOf('video') > -1;

        if (isVideo) {
            // Multiple upload for video
            let percentage: number | undefined = undefined;

            const uploader = new MultipartUploader({ file, thumbnailBase64String: thumbnail });

            uploader
                .onProgress(({ percentage: newPercentage }) => {
                    if (newPercentage !== percentage) {
                        percentage = newPercentage;
                        console.log(`${percentage}%`);
                    }
                })
                .onError((error) => {
                    console.error(error);
                    toast.error('There was a problem uploading the video. Please try again later.');
                })
                .onComplete((videoId) => {
                    createMessage({ videoId });
                });

            uploader.start();
        } else {
            // Single upload for image
            let presignedUrlResponse;
            try {
                presignedUrlResponse = await axios.post('/images', {
                    fileType: file.type,
                });

                await axios.put(presignedUrlResponse.data.url, file, {
                    headers: {
                        'Content-Type': file.type,
                    },
                });

                createMessage({ imageId: presignedUrlResponse.data.imageId });
            } catch {
                console.log('Error uploading image');
                toast.error('There was a problem uploading the image. Please try again later.');
            }
        }
    };

    const createMessage = async ({ videoId, imageId }: { videoId?: string; imageId?: string }) => {
        try {
            const payload = {
                conversationId: currentInbox.conversation.id,
                contents: '',
                generateThumbnail: false,
                videoId,
                imageId,
            };

            await axios.post('/conversations/messages', payload);

            getMessages(currentInbox.conversation.id);
            dispatcher(getVideoList(currentInbox.conversation.id));
            dispatcher(getInboxData());
            setImageUploading(false);
        } catch {
            console.log('Error creating message');
            toast.error('There was a problem sending the message. Please try again later.');
        }
    };

    const showChats = () => {
        switch (type) {
            case 'organizations':
                return (
                    <div>
                        {inboxData.organizations.map((item: any, index: number) => {
                            return (
                                <Organization
                                    key={index}
                                    item={item}
                                    index={index}
                                    updateGroups={updateGroups}
                                />
                            );
                        })}
                    </div>
                );
            case 'groups':
                return (
                    <Fragment>
                        <div className={styles.backButtonContainer} onClick={() => goBack()}>
                            <FiChevronLeft size={16} color="grey" />
                            <label className={styles.backButton}>Back</label>
                        </div>
                        <div>
                            {groups.map((item: any, index: number) => {
                                return (
                                    <SubGroup
                                        key={index}
                                        index={index}
                                        item={item}
                                        onGroupSelect={() => onGroupSelect(item, index)}
                                    />
                                );
                            })}
                        </div>
                    </Fragment>
                );
            case 'individuals':
                return (
                    <Fragment>
                        <div className={styles.backButtonContainer} onClick={() => goBack()}>
                            <FiChevronLeft size={16} color="grey" />
                            <label className={styles.backButton}>Back</label>
                        </div>
                        <div className={styles.groupNameContainer}>
                            <div className={styles.roundNameBigger}>
                                {getNamesCharacters(currentGroups.name)}
                            </div>
                            <label className={styles.groupBiggerHeading}>
                                {currentGroups.name}
                            </label>
                        </div>
                        <div>
                            {currentGroups.conversations.map((item: any, index: number) => {
                                return (
                                    <Individual
                                        key={index}
                                        index={index}
                                        item={item}
                                        userId={userSelected}
                                        getMessages={getMessages}
                                        setSelectedConversation={setSelectedConversation}
                                    />
                                );
                            })}
                        </div>
                    </Fragment>
                );
            default:
                <Fragment></Fragment>;
        }
    };

    return (
        <div style={{ height: '100%' }}>
            <div className={commonStyles.inboxContainer}>
                <div className={commonStyles.inboxSubContainer}>
                    <label className={commonStyles.inboxHeaderHeading}>Inbox</label>
                    {!user?.orgOnly ? (
                        <FiUserPlus
                            size={24}
                            onClick={() => history.push(routeUrls.addcontact)}
                            className={commonStyles.cursorer}
                        />
                    ) : null}
                </div>
                <InboxTabContainer />
                {showChats()}
            </div>
            <div style={{ height: '100%' }}>
                {emptyInbox ? (
                    <div className={commonStyles.emptyInboxContainer}>
                        <GreyText>Empty inbox. Add a contact to liven things up.</GreyText>
                    </div>
                ) : selectedConversation ? (
                    <div>
                        {setInfo ? (
                            <ConversationDetail
                                name={
                                    selectedConversation.name
                                        ? selectedConversation.name
                                        : `${selectedConversation.firstName} ${selectedConversation.lastName}`
                                }
                                toggle={() => toggleInfoSection()}
                                id={userSelected}
                                type={'group'}
                                removeConversation={() => {}}
                                showRemoveConversation={false}
                                profilePhoto={selectedConversation.profileImage}
                            />
                        ) : (
                            <div className={commonStyles.messagesContainers}>
                                <div className={commonStyles.messageContainerHeader}>
                                    <div className={commonStyles.mcHeaderSetting}>
                                        <div className={commonStyles.mcHeaderLeft}>
                                            {selectedConversation.profileImage ? (
                                                <>
                                                    <img
                                                        className={styles.roundImageList}
                                                        src={selectedConversation.profileImage}
                                                        alt="Profile"
                                                    />
                                                </>
                                            ) : selectedConversation.name ? (
                                                <div className={commonStyles.roundNameSelected}>
                                                    {getNamesCharacters(selectedConversation.name)}
                                                </div>
                                            ) : (
                                                <div className={commonStyles.roundNameSelected}>
                                                    {getNamesCharacters(
                                                        selectedConversation.firstName +
                                                            ' ' +
                                                            selectedConversation.lastName
                                                    )}
                                                </div>
                                            )}
                                            <div className={commonStyles.mcHeaderRight}>
                                                {selectedConversation.name
                                                    ? selectedConversation.name
                                                    : `${selectedConversation.firstName} ${selectedConversation.lastName}`}
                                            </div>
                                        </div>
                                        <FiInfo
                                            size={30}
                                            style={{ paddingRight: '5px', cursor: 'pointer' }}
                                            onClick={() => {
                                                setInfoSection(true);
                                            }}
                                        />
                                    </div>
                                </div>
                                {!inboxData.loadCompleted ? (
                                    <Spinner
                                        isLoading={true}
                                        className={commonStyles.messagesContainerBodyLoader}
                                    />
                                ) : (
                                    <div className={commonStyles.messagesContainerBody}>
                                        {imageUploading && (
                                            <div className={commonStyles.messageLoadingContainer}>
                                                <Loader active size="small" inline />
                                            </div>
                                        )}
                                        {(currentInbox.messages ?? []).map(
                                            (
                                                message: MessageType,
                                                index: number,
                                                allMessages: MessageType[]
                                            ) => {
                                                if (message.deleted) return null;

                                                const firstMessageFromOtherSender =
                                                    message.senderId !==
                                                        allMessages[index + 1]?.senderId &&
                                                    message.senderId !== user?.id;

                                                const nextMessageFromSender =
                                                    message.senderId ===
                                                    allMessages[index - 1]?.senderId;

                                                const renderMessage = [
                                                    firstMessageFromOtherSender && (
                                                        <span
                                                            className={styles.messageSender}
                                                            key={1}
                                                        >
                                                            {message.user.fullName}
                                                        </span>
                                                    ),
                                                    <Message
                                                        key={2}
                                                        firstMessageFromSender={
                                                            firstMessageFromOtherSender
                                                        }
                                                        nextMessageFromSender={
                                                            nextMessageFromSender
                                                        }
                                                        message={message}
                                                        conversationId={selectedConversation.id}
                                                    />,
                                                ];
                                                if (currentInbox.messages.length === index + 1) {
                                                    return (
                                                        <InView
                                                            key={index}
                                                            onChange={(inView) => {
                                                                if (inView) {
                                                                    loadData();
                                                                }
                                                            }}
                                                        >
                                                            <div>{renderMessage}</div>
                                                        </InView>
                                                    );
                                                } else if (index === 0) {
                                                    return (
                                                        <InView key={index}>
                                                            <div>{renderMessage}</div>
                                                        </InView>
                                                    );
                                                }
                                                return <div key={index}>{renderMessage}</div>;
                                            }
                                        )}
                                    </div>
                                )}
                                <div className={commonStyles.messageContainerFooter}>
                                    <div className={commonStyles.flexContainer}>
                                        <FilePicker afterFileChange={afterFileChange} />
                                        <div className={commonStyles.flexExtendToEnd}>
                                            <div
                                                className={[
                                                    commonStyles.inputContainer,
                                                    commonStyles.inputMessage,
                                                ].join(' ')}
                                            >
                                                <input
                                                    className={commonStyles.inputField}
                                                    placeholder="Message..."
                                                    value={inputMessage}
                                                    readOnly={onload}
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            sendMessage();
                                                        }
                                                    }}
                                                    onChange={(event) =>
                                                        setInputMessage(event.target.value)
                                                    }
                                                />
                                                <div
                                                    className={commonStyles.sendMessageIcon}
                                                    style={
                                                        !inputMessage
                                                            ? { backgroundColor: 'transparent' }
                                                            : {}
                                                    }
                                                >
                                                    <FiSend
                                                        size={22}
                                                        onClick={() => {
                                                            sendMessage();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
